<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button router-link="/materiais">
            <ion-icon :icon="backIcon"
          /></ion-button>
        </ion-buttons>
        <ion-segment @ionChange="segmentChanged($event)" :value="selected">
          <ion-segment-button value="learn"> Practicar </ion-segment-button>
          <ion-segment-button value="words"> Termos </ion-segment-button>
        </ion-segment>
        <logout-button />
      </ion-toolbar>
    </ion-header>
    <words-list v-if="selected == 'words'" />
    <guess-word v-else />
  </ion-page>
</template>

<script>
import { arrowBackOutline } from "ionicons/icons";
import LogoutButton from "@/components/LogoutButton.vue";
import WordsList from "@/components/WordsList.vue";
import GuessWord from "@/components/GuessWord.vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonButtons,
  IonButton,
  IonSegmentButton,
} from "@ionic/vue";

export default {
  name: "Practice",
  components: {
    LogoutButton,
    IonHeader,
    IonButtons,
    IonButton,
    IonToolbar,
    IonPage,
    IonSegment,
    IonSegmentButton,
    WordsList,
    GuessWord,
  },
  data() {
    return {
      backIcon: arrowBackOutline,
      selected: "learn",
    };
  },
  computed: {
    language() {
      return this.$store.state.user.language;
    },
  },
  methods: {
    segmentChanged(ev) {
      this.selected = ev.detail.value;
    },
  },
};
</script>

