<template>
  <ion-content :fullscreen="true">
    <ion-searchbar />
    <ion-list>
      <ion-item
        v-for="word in words"
        :key="word.id"
        button
        @click="buttonClick()"
        detail
      >
        <ion-note color="danger" slot="end">{{
          word.incorrect_count
        }}</ion-note>
        <ion-note color="success" slot="end">{{ word.correct_count }}</ion-note>
        <ion-label> {{ word.original }} </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonLabel,
  IonNote,
  IonSearchbar,
  IonList,
} from "@ionic/vue";

export default {
  name: "WordsList",
  components: {
    IonContent,
    IonItem,
    IonSearchbar,
    IonLabel,
    IonNote,
    IonList,
  },
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("user/getMaterials", this.user).then(
      (materials) => {
        console.log(materials);
      },
      (error) => {
        console.log(error);
      }
    );
  },
  computed: {
    words() {
      return this.$store.state.user.words;
    },
  },
};
</script>

