<template>
  <ion-content :fullscreen="true">
    <ion-card v-if="answer == null">
      <ion-card-header>
        <ion-card-subtitle
          >CAL E A MANEIRA CORRECTA DE DICIR EN GALEGO ...?</ion-card-subtitle
        >
        <ion-card-title>{{ nextWord }}</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <form @submit.prevent="handleGuess">
                <ion-item lines="full">
                  <ion-label position="floating">Resposta</ion-label>
                  <ion-input
                    @input="guess = $event.target.value"
                    type="text"
                    required
                  ></ion-input>
                </ion-item>

                <ion-row>
                  <ion-col>
                    <ion-button type="submit" color="danger" expand="block"
                      >Enviar</ion-button
                    >
                  </ion-col>
                </ion-row>
              </form>
            </ion-col></ion-row
          >

          <ion-row>
            <ion-col> Puntuación: {{ totalScore }} </ion-col>
            <ion-col> Acertada: {{ wordCorrectCount }}</ion-col>
            <ion-col> Errada: {{ wordIncorrectCount }}</ion-col></ion-row
          ></ion-grid
        >
      </ion-card-content>
    </ion-card>
    <ion-card v-else>
      <ion-card-header>
        <ion-card-title
          ><h1 v-if="!isCorrect">BO INTENTO, PERO NON É CORRECTO :(</h1>
          <h1 v-else>MOI BEN, É CORRECTO :D</h1>
        </ion-card-title>
      </ion-card-header>
      <ion-item-divider>
        <ion-label> As posbiles respostas eran: </ion-label>
      </ion-item-divider>

      <ion-item
        v-for="word in possibleAnswers"
        :key="word"
        button
        @click="
          $router.push({
            name: 'definition',
            params: { word: word.trim() },
          })
        "
        detail
      >
        <ion-label> {{ word }} </ion-label>
      </ion-item>
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button @click="getGuess" color="danger" expand="block"
                >Continuar</ion-button
              >
            </ion-col></ion-row
          >
          <ion-row>
            <ion-col> Puntuación: {{ totalScore }} </ion-col>
            <ion-col> Acertada: {{ wordCorrectCount }}</ion-col>
            <ion-col> Errada: {{ wordIncorrectCount }}</ion-col></ion-row
          ></ion-grid
        >
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import UserService from "@/services/user.service.js";

import {
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItemDivider,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
} from "@ionic/vue";

export default {
  name: "GuessWord",
  components: {
    IonContent,
    IonCard,
    IonCardHeader,
    IonItemDivider,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
  },
  data() {
    return {
      guess: null,
      nextWord: null,
      totalScore: null,
      wordCorrectCount: null,
      wordIncorrectCount: null,
      answer: null,
      isCorrect: null,
    };
  },
  methods: {
    getGuess() {
      UserService.getWord().then(
        (response) => {
          this.isCorrect = null;
          this.answer = null;
          this.nextWord = response.data.nextWord;
          this.totalScore = response.data.totalScore;
          this.wordCorrectCount = response.data.wordCorrectCount;
          this.wordIncorrectCount = response.data.wordIncorrectCount;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    handleGuess() {
      UserService.guessWord(this.guess).then(
        (response) => {
          this.isCorrect = response.data.isCorrect;
          this.answer = response.data.answer;
          this.nextWord = response.data.nextWord;
          this.totalScore = response.data.totalScore;
          this.wordCorrectCount = response.data.wordCorrectCount;
          this.wordIncorrectCount = response.data.wordIncorrectCount;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.getGuess();
  },
  computed: {
    possibleAnswers() {
      return (this.answer != null && this.answer.split(" - ")) || [];
    },
  },
};
</script>

